<template>
  <div>
    <div class="wrapper d-flex align-items-stretch">
      <MainMenu />
      <div id="content" class="p-4 p-md-5 pt-5">
        <h1 class="text-center">{{ $t("Copla.Writer.Title") }}</h1>
        <div>{{ $t("Copla.Writer.Intro") }}</div>
        <div class="border rounded secciones mt-4" id="configuration">
          <h2>{{ $t("Copla.Writer.Configuration") }}</h2>
          <div class="form-group row">
            <label for="viewer" class="col-2 col-form-label text-center">{{
              $t("Copla.Writer.Viewer")
            }}</label>
            <div class="col-10">
              <input
                type="number"
                class="form-control"
                name="viewer"
                id="viewer"
                min="1"
                v-model="viewer"
              />
            </div>
          </div>
        </div>
        <textarea
          v-model="transcription"
          id="transcription-box"
          class="border rounded secciones mt-4 col-12"
        ></textarea>
        <div class="border rounded secciones mt-4" id="server-box">
          <span v-html="server"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MainMenu from "../components/MainMenu";
export default {
  name: "Writer",
  components: {
    MainMenu
  },
  data() {
    return {
      server: "",
      transcription: "",
      viewer: 1,
      url: this.$route,
      base_url: process.env.VUE_APP_BASE_URL,
      sse: null
    };
  },
  mounted() {
    this.sseLaunch();
  },
  watch: {
    transcription: function(val) {
      let self = this;
      let lastChar = val.slice(-1);
      if (lastChar == " ") {
        self.add();
      }
    },
    viewer: function() {
      this.sse.close();
      this.sseLaunch();
    }
  },
  methods: {
    sseLaunch: function() {
      let self = this;
      let serverURL = "https://livedata." + this.base_url + "/";
      self.sse = new EventSource(serverURL + "api/v1/getSSE/" + this.viewer);
      self.sse.onmessage = function(e) {
        //let data = JSON.parse(e.data);
        self.server = e.data;
      };
    },
    add: function() {
      let self = this;
      let data = { _viewer: self.viewer, _data: self.transcription };
      let serverURL = "https://livedata." + this.base_url + "/";
      axios
        .post(serverURL + "api/v1/add", data, {
          headers: { "Content-Type": "text/plain" }
        })
        .then(function(response) {
          if (response.status == 200) {
            console.log("Bien");
          } else {
            console.log("Mal");
          }
        })
        .catch(error => console.log(error));
    }
  }
};
</script>

<style scoped>
h1 {
  font-size: 1.8em;
  font-weight: bold;
}
h2 {
  text-align: left;
  font-size: 1.3em;
  font-weight: bold;
}
.secciones {
  padding: 10px;
}
#transcription-box,
#server-box {
  min-height: 100px;
  max-height: 300px;
  overflow: scroll;
}
.wrapper {
  width: 100%;
}

h1 {
  font-size: 1.8em;
  font-weight: bold;
}
.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: white;
}
</style>
